import { Page } from '../shared/page'
import { ClientsTab } from './clients-tab'
import { CalendarTab } from './calendar-tab'
import { Tabs } from '../shared/tabs/tabs'
import { HomeHeader } from './shared/home-header'

export const HomePage = () => {

    const base = Page()
    base.append()

    const header = HomeHeader()
    base.append(header)

    const clients = ClientsTab()
    const calendar = CalendarTab()
    const tabs = Tabs([calendar, clients])
    tabs.hideNav()
    base.append(tabs)

    // emitter.on('flags-changed', ({ flags }) => {
    //     if (flags.FIRST_CLIENT_ADDED) {
    //         base.empty()
    //         base.append(FirstClientAdded())
    //     } else {
    //         base.empty()
    //         base.append(AddFirstClient())
    //     }
    // })

    base.on('enter', () => {
        tabs.showNav()
        clients.refresh()
        calendar.refresh()
    })

    return base
}