import router, { IRouteParams } from '../../base/lib/router'
import { Div } from '../../base/components/native/div'
import { waitFor } from '../../base/utils/wait'
import helpers from '../../helpers'
import services from '../../services'
import { EditClient } from './components/client'
import { EditDate } from './components/date'
import { EditText } from './components/text'
import { EditPhone } from './components/phone'
import { EditPrice } from './components/price'
import { EditTime } from './components/time'
import ldb from '../../base/lib/ldb'
// todo: move to history service
let initialHistoryLength = history.length;
let afterLoadHistoryLength = 0
window.onload = () => afterLoadHistoryLength = history.length

export const NewEditPage = () => {

    const base = Div()
    base.cssClass(helpers.styles.PAGE_BASE)

    function exit() {
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ query, from, to, params }: IRouteParams) {
        base.empty()

        let edit = EditText('ویرایش ' + getLabel(params.field), ldb.get(params.field))
        base.append(edit)
        edit.on('update', async () => {
            ldb.set(params.field, edit.getValue())
            router.back()
            // todo: move to history service
            // if (history.length === initialHistoryLength) router.back()
            // else router.goto(location.href.replace('/edit/' + params.field, ''))
        })
        edit.on('cancel', () => router.back())

        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}

function getLead() {
    switch (true) {
        case location.href.includes('appointments'):
            return 'appointments'
        case location.href.includes('clients'):
            return 'clients'
        default:
            return 'home'
    }
}

function getLabel(field: string) {
    switch (field) {
        case 'name':
            return 'نام'
        case 'description':
            return 'شرح کار'
        case 'fees':
            return 'دستمزد'
        case 'date':
            return 'تاریخ'
        case 'time':
            return 'ساعت'
        case 'cell':
            return 'شماره موبایل'
        case 'telegram':
            return 'شماره تلگرام'
        case 'clientId':
            return 'مشتری'
        case 'my-business-title':
            return 'نام کسب و کار شما'
        case 'service-title':
            return 'خدماتی که ارائه می‌دهید'
        default:
            return 'فیلد'
    }
}