import { Page } from '../../shared/page'
import { Div } from '../../../base/components/native/div'
import { PageHeader } from '../../shared/page-header'
import { MessagePreview } from '../shared/message-preview'
import router, { IRouteParams } from '../../../base/lib/router'
import { generateMessage } from './shared/generate-message'
import { ActionButton } from '../../shared/action-button'
import helpers from '../../../helpers'
import { editHintNote, messageNote } from './shared/content'
import { ActionBack } from '../../shared/action-back'
import { Img } from '../../../base/components/native/img'
import images from '../../../configs/images'
import locale from '../../../helpers/locale'

export const ReminderDetailsPage = () => {

    const base = Page()
    base.cssClass({ padding: '0 60px 0 20px' })

    const header = PageHeader('تنظیم یادآوری')
    base.append(header)

    const desc = Div(messageNote)
    desc.cssClass({ marginTop: '60px' })
    base.append(desc)

    const message = MessagePreview()
    base.append(message)

    const buttons = Div()
    buttons.cssClass({ marginTop: '30px', display: 'flex', gap: '16px', flexDirection: 'column' })
    base.append(buttons)

    const edit = ActionButton('ویرایش اطلاعات پیام', { background: '#fff', border: '#222', color: '#222' })
    edit.on('click', handleEdit)
    buttons.append(edit)

    const send = ActionButton('تایید نهایی', { background: '#1cd4a2', color: '#fff' })
    ///clients/:clientId/reminders/setup
    send.on('click', () => router.goto(location.pathname.replace(/\w+\/details/, 'setup')))
    buttons.append(send)

    const editHint = Div(editHintNote)
    const editHintIcon = Img(images.ICONS.FINGER_TOP, { width: 24 })
    editHintIcon.cssClass({ marginLeft: '10px' })
    editHint.prepend(editHintIcon)
    editHint.cssClass({ display: 'none' })
    buttons.append(editHint)

    const cancel = ActionBack(' لـغـو و برگشت')
    cancel.style({ display: 'none' })
    buttons.append(cancel)
    cancel.on('click', handleCancel)

    function handleEdit() {
        editHint.style({ display: 'flex' })
        cancel.style({ display: 'flex' })
        edit.style({ display: 'none' })
        send.style({ display: 'none' })
        const allSpans = message.el.querySelectorAll('span')
        allSpans.forEach(span => {
            span.classList.add('shake')
            span.addEventListener('click', () => {
                router.goto(location.href + '/edit/' + span.id)
            })
        })
    }

    function handleCancel() {
        editHint.style({ display: 'none' })
        cancel.style({ display: 'none' })
        edit.style({ display: 'flex' })
        send.style({ display: 'flex' })
        const allSpans = message.el.querySelectorAll('span')
        allSpans.forEach(span => {
            span.classList.remove('shake')
        })
    }
    // '/clients/:clientId/reminders/:appointmentId/setup': SetupReminderPage,
    base.on('enter', async ({ params }: IRouteParams) => { // todo: fix types
        // const messageBody = // services.reminders.getReminderMessage(params.clientId, params.appointmentId)
        const messageBody = await generateMessage(params.clientId, params.appointmentId)
        message.html(messageBody)
    })

    base.on('exit', ({ to }: IRouteParams) => {
        handleCancel()
        if (to === '/') { // Todo: check the design decision
            return base.style(helpers.styles.PAGE_EXIT, 0)
        }
    })

    return base
}