import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import { TAppointment } from "../../../interfaces/appointments"
import { ReminderStatus } from "../../../interfaces/reminder"
import services from "../../../services"
import { Loading } from "../loading"
import defined from "./statuses/defined"
import initial from "./statuses/initial"
import sent from "./statuses/sent"
import waiting from "./statuses/waiting"

export const AppointmentReminder = (item: TAppointment) => {

    const base = Div()
    base.cssClass({
        position: 'relative',
        border: '1px solid #414141',
        width: 'fit-content',
        borderRadius: '25px',
        padding: '4px 10px',
        fontSize: '13px',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        '&:active': {
            transform: 'scale(0.99)'
        }
    })

    const bellIcon = Img(images.ICONS.BELL, { width: 18 })
    base.append(bellIcon)

    const title = Div()
    base.append(title)

    const loading = Loading(undefined, 40)
    base.append(loading)

    ghostify(base, { bg: '#fff' })

    base.el.onclick = (e) => {
        // if it's not setup return
        e.stopPropagation()
        router.goto(`/clients/${item.clientId}/reminders/${item.id}/details`)
    }
    services.clients.byId(item.clientId).then((user: any) => {
        services.reminder.getSubscriptionStatus(user.contact.cell.replace('0', '98')).then(({ data, status }) => {
            loading.remove()
            if (status === 403 || status === 401 || !data) {
                // unauthorized
                title.text(initial.text)
                return
            }

            if (!data.chat_id) {
                // user has not joined the bot
                title.text(waiting.text)
                base.style(defined.style)
                return
            }

            title.text(defined.text)
            // base.prepend(defined.icon())
            base.style(defined.style)



            // case ReminderStatus.SENT:
            //     bellIcon.remove()
            //     title.text(sent.text)
            //     base.prepend(sent.icon())
            //     base.style(sent.style)
            //     break

        })
    })
    return base
}