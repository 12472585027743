
import router, { IRouteParams } from '../../../base/lib/router'
import state from '../../../base/services/state'
import { waitFor } from '../../../base/utils/wait'
import helpers from '../../../helpers'
import services from '../../../services'
import { Page } from '../../shared/page'
import { SetReminderWizard } from './wizard'

export const SetupReminderPage = () => {

    let _state: any = {}
    const base = Page()
    const wizard = SetReminderWizard()
    base.append(wizard)

    wizard.on('done', async () => {
        // await services.appointments.add(state.get('new-appointment'))
        // router.back()
        router.goto('/') // ? day=2021-09-01
        // if (_state.from === '/') return router.back()
        // return router.goto(`/`, { replace: true })
    })

    function exit({ to }: IRouteParams) {
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params, from, to, data }: IRouteParams) {

        const isLoggedIn = await services.auth.check()

        if (!isLoggedIn?.ok) return router.goto('/login', { replace: true, from: `clients/${params.clientId}/setup-reminder/` + params.appointmentId })

        wizard.reset()
        const client = await services.clients.byId(params.clientId)
        const appointment = await services.appointments.byId(params.appointmentId)
        state.set/* <TClient> */('new-reminder', { client, appointment })
        // _state.clientId = params.clientId
        // _state.from = from
        if (from === '/') base.style(helpers.styles.PAGE_EXIT)
        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}