import { Base } from "../../../../base/components/base"
import { Div } from "../../../../base/components/native/div"
import { CENTER } from "../../../../base/helpers/style"
import router from "../../../../base/lib/router"
import images from "../../../../configs/images"
import { ActionButton } from "../../../shared/action-button"

export const AppointmentsIntro = () => {

    const base = Base()
    // const picture = Img(images.BANNERS.BEAUTY_SESSION, { width: 256 })
    // const meeting = ActionButton('شرح کار انجام شده', { icon: images.ICONS.ADD})
    const meetdsc = Div('تاریخ، مبلغ دستمزد و توضیح کارهای انجام شده برای مشتری در جلسه‌ای که گذشت')
    const alerted = ActionButton('ثبت نوبت ', { icon: images.ICONS.ADD })
    const alertme = Div(' شرح کار نوبت گذشته یا<br>ثبت نوبت جدید برای مشتری و ارسال یادآور')
    base.append(alerted, alertme)
    // alerted.disable()
    // bad practice
    // db.all('appointments').then(items => picture.style({ display: items.length ? 'none' : 'block' }))
    alerted.el.onclick = () => router.goto(location.pathname + '/appointments/add')
    alerted.cssClass({
        margin: '20px auto 4px',
        width: 'fit-content',

    })
    meetdsc.cssClass({
        fontSize: '14px',
        margin: '10px 20px 30px',
        maxWidth: '280px'
    })
    alertme.cssClass({
        fontSize: '14px',
        margin: '10px 20px 10px',
        maxWidth: '280px',
        opacity: '.5',
        textAlign: 'center'

    })
    base.cssClass({
        ...CENTER,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        marginBottom: '100px'
    })

    return base
}