import { Base } from '../../base/components/base'
import { Div } from '../../base/components/native/div'
import { Img } from '../../base/components/native/img'
import router from '../../base/lib/router'
import state from '../../base/services/state'
import images from '../../configs/images'
import helpers from '../../helpers'
import { Price } from './price'

type PropType = 'text' | 'phone' | 'telegram' | 'price' | 'date' | 'time'
export const Prop = (label: string, type: PropType, field: string) => {
    const base = Div()
    const labelDiv = Div(label)
    const valueDiv = Div()
    const edit = Div('ویرایش')
    const pen = Img(images.ICONS.EDIT, { width: 28 })
    edit.prepend(pen)

    base.append(labelDiv, valueDiv, edit)

    edit.el.onclick = () => {
        base.emit('edit')
        state.set('edit', {
            label,
            type,
        })
        router.goto(location.href + '/edit/' + field)
    }
    function setContent(value: string | any) {
        
        let content = undefined
        valueDiv.empty()
        switch (type) {
            case 'date':
                content = Div(value)
                valueDiv.append(content)
                break
            case 'time':
                content = Div(`از ساعت ${helpers.locale.replaceLatinDigits(value.from)} تا ساعت ${helpers.locale.replaceLatinDigits(value.to)}`)
                valueDiv.append(content)
                break
            case 'price':
                content = Price(value)
                valueDiv.append(content)
                break
            case 'text':
                content = Div(value)
                valueDiv.append(content)
                break
            case 'phone':
                content = Div(value)
                valueDiv.append(content)
                break
            case 'telegram':
                content = Div(value)
                valueDiv.append(content)
                break
        }
    }

    labelDiv.cssClass({
        fontSize: '14px',
        color: 'gray',
    })
    valueDiv.cssClass({
        flexDirection: 'rtl',
        fontSize: '16px',
        color: 'black',
        marginBottom: '10px',
        // direction: type === 'text' ? 'rtl' : 'ltr',
    })
    edit.cssClass({
        position: 'absolute',
        left: '20px',
        top: '20px',
        opacity: '0.45',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: '14px',
        gap: '4px',
        backgroundColor: 'white',

    })
    base.cssClass({
        textAlign: 'right',
        paddingRight: '60px',
        marginTop: '24px',
        position: 'relative',
    })

    return Object.assign(base, { setData: setContent })
}

// <a href="https://t.me/+1234567890">Message on Telegram</a>
// <a href="tel:+1234567890">Call</a>
// <a href="mailto:
// <a href="https://wa.me/1234567890">Chat on WhatsApp</a>

