import { H3 } from "../../base/components/native/h"

export const Heading = (text: string, color = 'balck') => {
    const base = H3(text)
    base.cssClass({
        textAlign: 'right',
        paddingRight: '60px',
        margin: '20px 0 20px 0',
        color
    })
    return base
}