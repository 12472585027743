
import { Div } from '../../../../base/components/native/div'
import { TAppointment } from '../../../../interfaces/appointments'
import { TClient } from '../../../../interfaces/client'
import services from '../../../../services'
import { Heading } from '../../../shared/heading'
import { AppointmentItem } from './item'

export const AppointmentsList = () => {
    const base = Div()

    base.cssClass({
        width: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingBottom: '20px'
    })

    async function fill(client: TClient, _newId?: string) {
        const appointments = await services.appointments.byClient(client.id)
        appointments.sort((a: any, b: any) => a.date > b.date ? -1 : 1)
        let comingAppointments: TAppointment[] = []
        let todaysAppointments: TAppointment[] = []
        let beforeAppointments: TAppointment[] = []
        appointments.forEach((appointment: any) => {
            if (new Date(appointment.date) > new Date()) {
                comingAppointments.push(appointment)
            } else if (new Date(appointment.date).toDateString() === new Date().toDateString()) {
                todaysAppointments.push(appointment)
            } else {
                beforeAppointments.push(appointment)
            }
        })

        base.empty()

        if (comingAppointments.length) {
            base.append(Heading('نوبت‌های آینده'))
            comingAppointments.forEach(appendAppointment)
        }
        if (todaysAppointments.length) {
            base.append(Heading('نوبت‌های امروز'))
            todaysAppointments.forEach(appendAppointment)
        }
        if (beforeAppointments.length) {
            base.append(Heading('نوبت‌های گذشته'))
            beforeAppointments.forEach(appendAppointment)
        }

        function appendAppointment(appointment: TAppointment) {
            const item = AppointmentItem(appointment)
            item.on('repeat', async () => {
                const date = new Date(appointment.date)
                date.setDate(date.getDate() + 21)
                const newAppointmentId = await services.appointments.add({
                    ...appointment,
                    date,
                }) as string
                fill(client, newAppointmentId)
            })
            base.append(item)
            if (_newId === appointment.id) {
                item.style({ backgroundColor: '#64ddc044' })
                item.style({ backgroundColor: '' }, 400)
            }
        }

    }

    return Object.assign(base, {
        fill
    })
}