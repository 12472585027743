import { TAppointment } from "../interfaces/appointments"
import appointments from "./appointments"
import db from "./db"

export default {
    async run() {
        console.log('Sync service is running')
        const allAppointments = await <Promise<TAppointment[]>>appointments.getAll(0)
        const isNotSynced = allAppointments.filter((appointment) => !appointment.synced)
        console.log('Appointments not synced:', isNotSynced)
        if (isNotSynced.length) {
            isNotSynced.forEach(async (appointment) => {
                console.log('Syncing appointment:', appointment)
                // await appointments.push(appointment)
                console.log('Appointment synced:', appointment)
            })
        }
    }
}