import { Base } from '../../base/components/base'
import { Scrollable } from '../../base/utils/scrollable'

export const Body = (adjustedHeight = 0) => {
    const base = Scrollable(Base())

    base.cssClass({
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100vw',
        position: 'relative',
        margin: '0',
        padding: '60px 60px 20px',
        height: (window.innerHeight - adjustedHeight) + 'px',
    })

    return base
}
