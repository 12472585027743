
import images from '../../../../configs/images'
import { TClient } from '../../../../interfaces/client'
import { Tab } from '../../../shared/tabs/tab'
import { AppointmentsIntro } from './intro'
import { AppointmentsList } from './list'

export const AppointmentsTab = (force: any = {}) => {

    const base = Tab()
    const intro = AppointmentsIntro()
    const list = AppointmentsList()
    base.append(list, intro)

    base.cssClass({
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '60px 0 20px'
    })

    return Object.assign(base, {
        refresh(client: TClient) {
            list.fill(client)
        },
        label: 'نوبت‌ها',
        id: 'appointments',
        icon: images.ICONS.BELL,
        selected: true
    })
}