import { Wizard } from '../shared/slide/wizard'
import { BotSlide } from './slides/bot'
import { TelegramSlide } from './slides/telegram'
import { VerifySlide } from './slides/verify'

export const LoginWizard = () => {

    const telegramSlide = TelegramSlide()
    const botSlide = BotSlide()
    const verify = VerifySlide()
    const base = Wizard([telegramSlide, botSlide, verify])

    return base
}