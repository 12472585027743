import { Div } from "../../../base/components/native/div";
import { EASE, HIDE, SHOW, X } from "../../../base/helpers/style";
import router from "../../../base/lib/router";
import clients from "../../../services/clients";
import { emitter } from "../../../services/emitter";
import { PlusIcon } from "../../shared/plus-icon";

export const HomeHeader = () => {

    const base = Div()
    base.cssClass({
        height: '60px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '60px',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
    })

    const add = Div()
    add.cssClass({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '20px',
        zIndex: '100',
        ...EASE(.36),
        ...HIDE,
    })
    base.append(add)
    add.el.onclick = () => router.goto('/clients/add')

    const plus = PlusIcon()
    add.append(plus)

    const title = Div('مشتری')
    title.cssClass({
        margin: '-5px -10px 0 20px',
        fontSize: '20px',
        color: '#ea4c89',
        fontWeight: 'bold',
    })
    add.append(title)


    checkClientsCount()
    clients.on('client-added', checkClientsCount)

    emitter.on('tab-clicked', (tab) => {
        if (tab === 'clients') {
            checkClientsCount()
        } else {
            add.style({ ...X(-130), ...HIDE })
        }
    })

    async function checkClientsCount() {
        const count = await clients.count()
        if (count > 3) {
            add.style({ ...X(0), ...SHOW })
        } else {
            add.style({ ...X(-130), ...HIDE })
        }
    }

    return base
}

