import idb from '../base/lib/idb'
import ldb from '../base/lib/ldb'
import { emitter } from '../base/utils/emitter'
import { uuidv4 } from '../base/utils/id-generator'
import { TAppointment } from '../interfaces/appointments'

const db = idb('flep-db')
const init = () => {
    // check if initiated
    // if (ldb.get('init')) return Promise.resolve(true)
    if (!ldb.get('init')) ldb.save('init', true)

    return new Promise(async (resolve, reject) => {
        const { version, objectStoreNames } = await db.info()
        if (!objectStoreNames.contains('clients')) {
            await db.createStore('clients', version + 1, { keyPath: 'id', indices: ['_id', 'at', 'name', 'contact', 'synced'] })
        }
        if (!objectStoreNames.contains('appointments')) {
            await db.createStore('appointments', version + 2, { keyPath: 'id', indices: ['_id', 'at', 'date', 'clientId', 'synced'] })
        }
        await db.createindex('clients', version + 3, 'isDeleted', { unique: false })
        await db.createindex('appointments', version + 4, 'isDeleted', { unique: false })
        emitter.emit('db-ready')
        return resolve(true)
    })
}
export default {
    ...db,
    init
}