import router, { IRouteParams } from '../../base/lib/router'
import { Div } from '../../base/components/native/div'
import { waitFor } from '../../base/utils/wait'
import helpers from '../../helpers'
import services from '../../services'
import { EditClient } from './components/client'
import { EditDate } from './components/date'
import { EditText } from './components/text'
import { EditPhone } from './components/phone'
import { EditPrice } from './components/price'
import { EditTime } from './components/time'
// todo: move to history service
let initialHistoryLength = history.length;
let afterLoadHistoryLength = 0
window.onload = () => afterLoadHistoryLength = history.length

export const EditPage = () => {

    const base = Div()
    base.cssClass(helpers.styles.PAGE_BASE)

    function exit() {
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ query, from, to, params }: IRouteParams) {
        const lead = getLead()
        let id = lead === 'appointments' ? params.appointmentId : params.clientId
        const data = await services[lead as 'appointments' | 'clients'].byId<any>(id)
        base.empty()

        let edit = params.field === 'cell' || params.field === 'telegram' ?
            EditPhone('ویرایش ' + getLabel(params.field), data.contact[params.field]) :
            params.field === 'fees' ?
                EditPrice('ویرایش ' + getLabel(params.field), data[params.field]) :
                params.field === 'date' ?
                    EditDate('ویرایش ' + getLabel(params.field), data[params.field]) :
                    params.field === 'time' ?
                        EditTime('ویرایش ' + getLabel(params.field), data[params.field]) :
                        params.field === 'clientId' ?
                            EditClient('ویرایش ' + getLabel(params.field), data[params.field]) :
                            EditText('ویرایش ' + getLabel(params.field), data[params.field])
        base.append(edit)
        edit.on('update', async () => {
            const payload = params.field === 'cell' || params.field === 'telegram' ? {
                ...data,
                contact: {
                    ...data.contact,
                    [params.field]: edit.getValue()
                }
            } : { ...data, [params.field]: edit.getValue() }
            await services[lead as 'appointments' | 'clients'].update(id, payload)

            router.back()
            // todo: move to history service
            // if (history.length === initialHistoryLength) router.back()
            // else router.goto(location.href.replace('/edit/' + params.field, ''))
        })
        edit.on('cancel', () => router.back())

        await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
        base.style(helpers.styles.PAGE_ENTER)
    }

    return Object.assign(base, { enter, exit })
}

function getLead() {
    switch (true) {
        case location.href.includes('appointments'):
            return 'appointments'
        case location.href.includes('clients'):
            return 'clients'
        default:
            return 'home'
    }
}

function getLabel(field: string) {
    switch (field) {
        case 'name':
            return 'نام'
        case 'description':
            return 'شرح کار'
        case 'fees':
            return 'دستمزد'
        case 'date':
            return 'تاریخ'
        case 'time':
            return 'ساعت'
        case 'cell':
            return 'شماره موبایل'
        case 'telegram':
            return 'شماره تلگرام'
        case 'clientId':
            return 'مشتری'
        default:
            return 'فیلد'
    }
}