import { Base } from "../../base/components/base"
import { Div } from "../../base/components/native/div"
import router, { IRouteParams } from "../../base/lib/router"
import { waitFor } from "../../base/utils/wait"
import helpers from "../../helpers"
import { TAppointment } from "../../interfaces/appointments"
import services from "../../services"
import { ActionBack } from "../shared/action-back"
import { ActionButton } from "../shared/action-button"
import { Body } from "../shared/body"
import { PageHeader } from "../shared/page-header"
import { Prop } from "../shared/prop"


export const RepeatAppointmentPage = () => {

    const base = Base()
    const head = PageHeader('تکرار نوبت در آینده')
    const body = Body()
    const date = Prop('تاریخ', 'date', 'date')
    const time = Prop('ساعت', 'time', 'time')
    const fees = Prop('دستمزد', 'price', 'fees')
    const desc = Prop('شرح کار', 'text', 'description')
    const next = ActionButton('ثبت')
    const prev = ActionBack('لغو')
    const keys = Div()
    keys.append(next, prev)

    next.on('click', () => {
        // const newAppointment:Appointment = {
        //     date: new Date(date.getData()),
        //     time: time.getData(),
        //     fees: parseInt(fees.getData()),
        //     description: desc.getData(),
        // }


    })
    prev.on('click', () => router.back())

    body.append(date, time, fees, desc, keys)
    base.append(head, body, /* email, notes, address, birthday */)

    function setData(appointment: TAppointment) {
        const later = new Date(appointment.date)
        later.setDate(later.getDate() + 30)
        date.setData(helpers.date.format(later))
        time.setData(appointment.time)
        fees.setData(appointment.fees)
        desc.setData(appointment.description)
    }

    base.cssClass(helpers.styles.PAGE_BASE)
    body.cssClass({
        marginTop: '45px',
        padding: '0 0 16px 16px',
    })
    keys.cssClass({
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        margin: '60px',
        gap: '16px',
    })

    function exit({ to }: IRouteParams) {
        if (to?.includes('add') || to?.includes('edit')) {
            return base.style(helpers.styles.PAGE_EXIT_UP)
        }
        base.style(helpers.styles.PAGE_EXIT)
    }

    async function enter({ params }: IRouteParams) {
        try {
            const _id = params.appointmentId
            const appointment = await services.appointments.byId<any>(_id)
            setData(appointment)
        } catch (error) {
            base.append(Div('خطا در بارگذاری اطلاعات' + error.message))
        } finally {
            await waitFor(helpers.styles.PAGE_TRANSITION_DURATION)
            base.style(helpers.styles.PAGE_ENTER)
        }
    }

    return Object.assign(base, { enter, exit })
}