import ldb from "../../../../base/lib/ldb";
import helpers from "../../../../helpers";
import { TAppointment } from "../../../../interfaces/appointments";
import { TClient } from "../../../../interfaces/client";
import services from "../../../../services";

export async function generateMessage(clientId: string, appointmentId: string) {
    const client = await services.clients.byId<TClient>(clientId) // Todo: why T?
    const appointment = await services.appointments.byId<TAppointment>(appointmentId)

    const name = client?.name
    const date = helpers.date.format(appointment.date)
    const { from, to } = appointment.time
    return `
    ${name} عزیز،
    <br/>
    <br/>
    شما در تاریخ ${date} از ساعت ${helpers.locale.replaceLatinDigits(from)} تا ساعت ${helpers.locale.replaceLatinDigits(to)} 
    وقت 
    <span id="service-title">
    ${ldb.get('service-title') || 'مشاوره'}
        
    </span>
     دارید.
    
    <br/>
    منتظر حضور شما هستیم!
    <br/>
    <br/>
    با سپاس
    <br/>
    <span id="my-business-title">
        ${ldb.get('my-business-title') || 'نام کسب و کار شما'}
    </span>
    `

}