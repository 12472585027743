import { Div } from '../../../../base/components/native/div'

export const RangeMask = () => {

    const base = Div()
    const topMask = Div()
    const bottomMask = Div()
    base.append(topMask, bottomMask)


    const maskStyle = {
        position: 'absolute',
        height: '60px',
        backgroundColor: 'white',
        top: '0',
        right: '0',
        left: '10px',
        zIndex: '99',
        opacity: '.88',
        pointerEvents: 'none',
    }

    topMask.cssClass({ ...maskStyle, borderBottom: '1px solid #00000033' })
    bottomMask.cssClass({ ...maskStyle, top: '120px', borderTop: '1px solid #00000033' })

    return base
}