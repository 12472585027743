
import { TransactionYear } from "./year"
import { TransactionsList } from "./list"
import { Div } from "../../../../base/components/native/div"
import images from "../../../../configs/images"
import { TClient } from "../../../../interfaces/client"
import services from "../../../../services"
import { Tab } from "../../../shared/tabs/tab"
import { TransactionTotal } from "./total"

export const TransactionsTab = () => {

    // Base
    const base = Tab()
    base.cssClass({
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '60px 0 20px',
        position: 'relative',

    })

    // Add Transaction
    // const plus = PlusIcon()
    // plus.cssClass({
    //     position: 'absolute',
    //     top: '0',
    //     left: '0',
    //     zIndex: '999',
    // })
    // plus.el.onclick = () => router.goto(location.pathname + '/incomes/add')
    // base.append(plus)

    // Transactions
    const list = TransactionsList()

    async function refresh(client: TClient) {
        base.empty()

        const data = await services.transactions.all(client)
        if (data.total === 0) {
            const notAddedYet = Div('هنوز هیچ مبلغی از مشتری دریافت نشده است :-(')
            notAddedYet.cssClass({
                fontSize: '16px',
                textAlign: 'center',
                margin: '50px auto',
                padding: '60px',
                color: '#999',
            })
            base.append(notAddedYet)
            return
        }
        const total = TransactionTotal(data.total)

        const line = Div('')
        line.cssClass({
            position: 'absolute',
            top: '0',
            right: '165px',
            bottom: '60px',
            width: '1px',
            backgroundColor: '#ea4c8988',
            zIndex: '6',
            // backgroundImage: 'linear-gradient(to left, #ea4c8988 49%, transparent 50%, transparent 51%)',
            // backgroundSize: '2px 100%',
            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'calc(100% - 164px) top',
            // pointerEvents: 'none'
        })
        base.append(list)
        list.append(line)
        list.append(total)
        data.byYear.forEach(year => {
            list.append(TransactionYear(year))
        })
    }


    return Object.assign(base, {
        refresh,
        label: 'حساب و کتاب',
        id: 'transactions',
        icon: images.ICONS.COINS,

    })
}