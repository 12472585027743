import { Frame } from "../slider/frame"
import { Window } from "../slider/window"
import { Month } from "./month"
import * as jalaaliJs from "../../../lib/jalaali-js"
import { emitter } from "../../../services/emitter"
import { TSwipe } from "../../home/calendar-tab"

export const Months = () => {

    const base = Window()
    base.cssClass({
        backgroundColor: 'white',
    })

    let currentMonth = {
        jm: 0,
        jy: 0,
    }

    base.on('swipe-end', ({ direction, frame, index }: TSwipe) => {

        frame.empty()
        let jM = currentMonth.jm + index + direction
        const yearsAdded = jM / 12 > Math.floor(jM / 12) ? Math.floor(jM / 12) : Math.floor(jM / 12) - 1
        let jY = currentMonth.jy + yearsAdded
        jM = jM % 12
        if (jM === 0) {
            jM = 12
        }

        const day = Month({ jm: jM, jy: jY })
        frame.append(day)
    })

    base.on('swipe', () => {
        emitter.emit('month-clicked')
    })

    // emitter.on('move-month-by-one', (direction: number) => {
    //     const { jm, jy } = currentMonth
    //     console.log('move-month-by-one', jm, jy, direction);
    //     goto(jm + direction, jy)
    // })

    function goto(jm: number, jy: number) {

        currentMonth = { jm, jy }

        const framePrev = Frame()
        const dayPrev = Month(prevMonth(jm, jy))
        framePrev.append(dayPrev)

        const frameCurrent = Frame()
        const dayCurrent = Month({ jm, jy })
        frameCurrent.append(dayCurrent)

        const frameNext = Frame()
        const dayNext = Month(nextMonth(jm, jy))
        frameNext.append(dayNext)

        base.init([framePrev, frameCurrent, frameNext])
    }

    return Object.assign(base, {
        goto,
    })
}

function nextMonth(jm: number, jy: number) {
    let nextJm = jm + 1
    let nextJy = jy
    if (nextJm > 12) {
        nextJm = 1
        nextJy++
    }
    return { jm: nextJm, jy: nextJy }
}

function prevMonth(jm: number, jy: number) {
    let prevJm = jm - 1
    let prevJy = jy
    if (prevJm < 1) {
        prevJm = 12
        prevJy--
    }
    return { jm: prevJm, jy: prevJy }
}