export default {
    BANNERS: {
        RANK: '/images/rankr-banner.svg',
        BOOKS: '/images/book.jpg',
        TV: '/images/tv.jpg',
        CAMP: '/images/camp.jpg',
        HAPPY_CLIENT: '/images/happy-client.jpg',
        HAPPY_CLIENT_2: '/images/happy-client-2.jpg',
        BEAUTY_SESSION: '/images/beauty-session.jpg',
        PROFILE_GRAY: '/images/profile-gray.jpg',
        HURRAY: '/images/hurray.svg',
    },
    ICONS: {
        ARROW_LEFT: '/images/arrow-left.svg',
        ARROW_LEFT2: '/images/arrow-left-2.svg',
        REPEAT: '/images/repeat-icon.svg',
        COINS: '/images/coins.svg',
        BELL: '/images/bell-icon.svg',
        SCRIBBLE: '/images/scribble.svg',
        EDIT: '/images/edit-icon.svg',
        LOCK: '/images/lock.svg',
        CONFIRM: '/images/confirm-icon.svg',
        DELETE: '/images/delete-icon.svg',
        CANCEL: '/images/cancel-icon.svg',
        ADD: '/images/add-icon.svg',
        DOWNLOAD: '/images/download-icon.svg',
        BACK: '/images/back-icon.svg',
        GOOGLE: '/images/google-icon.svg',
        SPIRAL: '/images/google.svg',
        STAR: '/images/star-icon.svg',
        X: '/images/x-icon.svg',
        DOTS: '/images/dots-icon.svg',
        THUMBS_UP: '/images/thumbs-up-icon.svg',
        THUMBS_DOWN: '/images/thumbs-down-icon.svg',
        ENTER: '/images/enter-icon.svg',
        CLIENTS: '/images/clients.svg',
        CALENDAR: '/images/calendar.svg',
        UP_ARROW: '/images/up-arrow.svg',
        DOWN_ARROW: '/images/down-arrow.svg',
        BULB: '/images/bulb.svg',
        INFO: '/images/information.svg',
        COPY: '/images/copy.svg',
        TELEGRAM: '/images/telegram.svg',
        WAITING: '/images/waiting.svg',
        FINGER_TOP: '/images/finger-top.svg',
    }
}