import { Button } from "../../../base/components/native/button"
import { Div } from "../../../base/components/native/div"
import { CENTER } from "../../../base/helpers/style"
import ldb from "../../../base/lib/ldb"
import helpers from "../../../helpers"
import services from "../../../services"

export const User = () => {
    const base = Div()
    const num = (ldb.get('flep-user')?.phone || '').replace('98', '0')
    const number = helpers.locale.replaceLatinDigits(num)
    const phone = Div(number).style({ fontSize: '22px' })
    base.append(phone)
    base.cssClass({
        position: 'relative',
        fontSize: '18px',
        padding: '20px',
        overflow: 'hidden',
        backgroundColor: '#dbf9f2',
        borderRadius: '20px',
        margin: '10px 0 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'

    })
    const exit = Div('خروج').style({ cursor: 'pointer', padding: '10px 20px', borderRadius: '10px', backgroundColor: '#f9f9f9', color: '#333', ...CENTER })
    base.append(exit)

    exit.el.onclick = async () => {
        ldb.remove('flep-user')
        await services.auth.logout()
        base.emit('exit')
    }
    return Object.assign(base, {
        show() {
            base.style({ display: 'flex' })
        },
        hide() {
            base.style({ display: 'none' })
        }
    })
}