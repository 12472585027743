import { ghostify } from "../../base/components/advanced/ghost/ghost";
import { Div } from "../../base/components/native/div";
import { ROUND } from "../../base/helpers/style";
import { Bar } from "./bar";

export const PlusIcon = () => {
    const base = Div()
    const v = Bar(4, { bg: '#ea4c89' })
    const h = Bar(4, { bg: '#ea4c89' })
    v.style({
        transform: 'rotate(90deg) translateX(10px)'
    })
    h.style({
        transform: 'translateY(6px)'
    })
    base.append(v, h)
    ghostify(base, { bg: '#ccc' });
    base.cssClass({
        ...ROUND,
        width: '60px',
        height: '60px',
        padding: '18px',
        transition: 'all .16s',
        zIndex: '99999',
        flexShrink: '0',
        cursor: 'pointer',
        '&:hover': {
            opacity: '.8',
        },
        '&:active': {
            transform: 'scale(.9)' // Tdod: fix this. if we have other transform, it will not be overriden
        },
    })

    return base
};
