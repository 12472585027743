import { Div } from '../../../../base/components/native/div'
import { RangeMask } from './range-mask'
import { Time } from './time'

export const RangeWrapper = () => {

    const base = Div()

    base.append(Time(''))

    base.cssClass({
        scrollSnapType: 'y mandatory',
        height: '180px',
        overflowY: 'scroll',
    })

    return base
}