import { Div } from '../../../../base/components/native/div'
import { RangeMask } from './range-mask'
import { RangeWrapper } from './range-wrapper'
import { Time } from './time'

export const MMPicker = () => {

    const base = Div()
    const wrapper = RangeWrapper()
    const mask = RangeMask()
    base.append(mask, wrapper)

    const minuteSpans = 12
    for (let i = 0; i < minuteSpans; i++) {
        let minute = ('0' + i * 5).slice(-2)
        if (minute === '24') minute = '00'
        const time = Time(minute)
        wrapper.append(time)
    }
    wrapper.append(Time(''))

    base.on('mounted', () => {
        wrapper.el.scrollTop = (minuteSpans - 6) * 60
    })

    base.cssClass({
        position: 'relative',

    })

    return Object.assign(base, {
        getValue() {
            const scrolledTop = minuteSpans - Math.round(wrapper.el.scrollTop / 60)
            let calculatedMinutes = 12 - scrolledTop
            return ('0' + calculatedMinutes * 5).slice(-2)
        },
        setValue(mm: string) {
            let index = Math.floor(parseInt(mm) / 5)
            setTimeout(() => {
                wrapper.el.scrollTop = index * 60
            }, 100);
        }
    })
}