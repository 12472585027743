import { SVG } from "../../base/components/native/svg";

export const Loading = (color = '#FF156D88', width = 100) => {
    const contents = `
    <circle fill="${color}"  stroke-width="5" opacity=".7" r="15" cx="35" cy="100">
        <animate attributeName="cx" calcMode="spline" dur="1.6" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin="0"></animate>
    </circle>
    <circle fill="${color}"  stroke-width="5" opacity=".55" r="15" cx="35" cy="100">
        <animate attributeName="cx" calcMode="spline" dur="1.6" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin="0.05"></animate>
    </circle>
    <circle fill="${color}"  stroke-width="5" opacity=".4" r="15" cx="35" cy="100">
        <animate attributeName="cx" calcMode="spline" dur="1.6" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin=".1"></animate>
    </circle>
    <circle fill="${color}"  stroke-width="5" opacity=".25" r="15" cx="35" cy="100">
        <animate attributeName="cx" calcMode="spline" dur="1.6" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin=".15"></animate>
    </circle>
    <circle fill="${color}"  stroke-width="5" opacity=".1" r="15" cx="35" cy="100">
        <animate attributeName="cx" calcMode="spline" dur="1.6" values="35;165;165;35;35" keySplines="0 .1 .5 1;0 .1 .5 1;0 .1 .5 1;0 .1 .5 1" repeatCount="indefinite" begin=".2"></animate>
    </circle>`

    const viewBox = '0 75 200 50'
    const base = SVG(contents, viewBox)

    base.cssClass({
        width: width + 'px',
        height: width / 2 + 'px',
    })

    return base
};
