import { Div } from "../../../base/components/native/div"

export const MessagePreview = () => {
    const base = Div()
    base.cssClass({
        backgroundColor: '#ea4c8922',
        padding: '24px',
        borderRadius: '30px',
        fontSize: '18px',
        borderBottomRightRadius: '0',
        marginBottom: '10px',
        marginTop: '20px',
        position: 'relative',
    })

    const contents = Div()
    base.append(contents)

    const corner = Div()
    corner.cssClass({
        width: '30px',
        height: '30px',
        position: 'absolute',
        right: '-30px',
        bottom: '0',
        backgroundColor: '#ea4c8922',
        maskImage: 'radial-gradient(circle at top right, transparent 30px, black 30px)',
        maskSize: '100% 100%',
        maskRepeat: 'no-repeat',
    })
    base.append(corner)

    return Object.assign(base, {
        html(text: string) {
            contents.html(text)
            return this
        }
    })
}