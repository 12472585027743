import { Base } from "../../../../base/components/base"
import images from "../../../../configs/images"


export const ProfileImage = () => {
    const base = Base()
    let W = window.innerWidth - 100
    if (W > 220) W = 220
    base.cssClass({
        width: W + 'px',
        height: W + 'px',
        borderRadius: '50%',
        margin: '80px auto 20px',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        opacity: '0.2',
        backgroundImage: `url(${images.BANNERS.PROFILE_GRAY})`,
    })

    return base
}