import date from './date'
import locale from './locale'
import strings from './strings'
import styles from './styles'

export default {
    styles,
    locale,
    date,
    strings
}