import { Img } from "../../../../base/components/native/img";
import images from "../../../../configs/images";

export default {
    icon: () => Img(images.ICONS.WAITING, { width: 16 }),
    style: {
        borderColor: '#04d1b633',
        color: '',
        backgroundColor: '#04d1b633',
    },
    text: 'در انتظار عضویت کاربر در بات'
}

