import { Div } from "../../../../base/components/native/div"
import ldb from "../../../../base/lib/ldb"
import state from "../../../../base/services/state"
import images from "../../../../configs/images"
import helpers from "../../../../helpers"
import { ActionBack } from "../../../shared/action-back"
import { ActionButton } from "../../../shared/action-button"
import { PageHeader } from "../../../shared/page-header"
import { WizardSlide } from "../../../shared/slide/slide"
import { MessagePreview } from "../../shared/message-preview"

export const SetReminderSendSlide = () => {
    let t = ''
    let l = ''
    const base = WizardSlide()
    const header = PageHeader('ارسال پیام')
    const desc = Div(`
        لطفا لینک زیر را برای مشتری ارسال کنید تا عضو بات تلگرامی شود و یادآوری‌ها را دریافت کند.
        <br/>
        به محض عضویت،‌ پیام‌های شما به صورت خودکار برای مشتری ارسال می‌شود.
        `)
    desc.cssClass({
        margin: '40px 0 -20px',
        // display: 'none'
    })
    base.append(desc)

    const message = MessagePreview()
    const copy = ActionButton('کپی پیغام', { icon: images.ICONS.COPY, background: '#fff', color: '#222', border: '#222' })
    const send = ActionButton('ارسال از طریق تلگرام', { icon: images.ICONS.TELEGRAM, background: '#fff', color: '#222', border: '#222' })
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(header, message, copy, send, prev)
    base.append(keys)

    send.on('click', () => {
        const text = state.get('new-reminder')
        if (navigator.share) {
            navigator.share({
                title: 'Share via Telegram',
                text: t, // The text to be shared
                url: ''     // You can leave this empty if you just want to share the text
            }).then(() => {
                base.emit('done')
                console.log('Sharing successful');
            }).catch((error) => {
                console.error('Sharing failed:', error);
            });
        } else {
            alert('Web Share API is not supported in your browser.');
        }
        base.emit('done')
    })

    copy.on('click', () => {
        navigator.clipboard.writeText(l).then(() => {
            copy.setText('کپی شد')
            setTimeout(() => {

                base.emit('done')
            }, 399);

        }).catch(err => {
            console.error('Could not copy text: ', err)
        })
    })
    prev.on('click', () => base.emit('prev'))
    base.on('enter', () => {
        const body = state.get('new-reminder')

        const title = ldb.get('my-business-title')
        const { client, joinToken } = state.get('new-reminder')
        // const appointment = state.get('new-reminder')?.appointment
        const greeting = client?.name + ' ' + 'عزیز،'
        // const date = helpers.date.format(appointment?.date)
        // const time = helpers.locale.replaceLatinDigits('از ساعت ' + appointment?.time?.from + ' تا ' + appointment?.time?.to)

        // Check if already joined otherwise show the link
        const more = 'برای اطلاع‌رسانی سریع و به‌موقع درباره نوبت‌های خود و دریافت مطالب سودمند و به‌روز، لطفاً با کلیک روی لینک زیر به بات تلگرامی ما بپیوندید:'
        const link = 'https://t.me/flepuserbot?start=' + joinToken
        const thanks = 'با سپاس فراوان'
        const text = `${greeting}\n\n ${more}\n\n${link}\n\n${thanks}\n${title}`
        // business.html(state.get('new-reminder')?.title)
        l = link
        t = text

        // Optionally, ensure the element is focused
        setTimeout(() => {
            message.text(link)
        }, 150)

        message.html(body.link)


    })

    keys.cssClass({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}