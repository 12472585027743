import { Img } from "../../../../base/components/native/img";
import images from "../../../../configs/images";

export default {
    icon: () => Img(images.ICONS.INFO, { width: 16 }),
    style: {
        // borderColor: '#0d9de666',
        // borderColor: '#aaa',
        borderColor: 'transparent',
        // color: '#0d9de6',
        backgroundColor: '#eee',
        opacity: 0.8
    },
    text: ' وقت   به مشتری یادآوری می‌شود '
}