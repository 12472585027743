import { Div } from "../../../base/components/native/div";
import router from "../../../base/lib/router";
import images from "../../../configs/images";
import services from "../../../services";
import { ActionButton } from "../../shared/action-button";
import { Loading } from "../../shared/loading";
import { PlusIcon } from "../../shared/plus-icon";
import { AddFirstClient } from "./add-first-client";
import { ClientListItem } from "./client-list-item";

export const ClientList = () => {
    const base = Div()


    base.cssClass({
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        padding: 'calc(0px + env(safe-area-inset-top)) 100px 0',
    })

    async function load(page = 0) {
        // try catch
        try {
            const loading = Loading()
            base.append(loading)
            const clients = await services.clients.get(page)
            base.empty()
            clients.forEach(add);
        } catch (error) {
            console.error('Error loading clients:', error)
            // Handle the error here, e.g. show an error message to the user
        }
    }

    function add(client: any) {
        base.append(ClientListItem(client))
    }

    return Object.assign(base, { load })
};
