import { Img } from "../../../../base/components/native/img";
import images from "../../../../configs/images";

export default {
    icon: () => Img(images.ICONS.INFO, { width: 16 }),
    style: {
        borderColor: '#0d9de633',
        color: '#0d9de6',
        backgroundColor: '#0d9de633',
    },
    text: 'این وقت روز قبل به مشتری یادآوری می‌شود '
}