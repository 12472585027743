import { Button } from "../../../base/components/native/button"
import { Div } from "../../../base/components/native/div"
import ldb from "../../../base/lib/ldb"
import services from "../../../services"

export const User = () => {
    const base = Div()
    const phone = Div(ldb.get('flep-user')?.phone).style({ fontSize: '14px', color: '#666' })
    base.append(phone)
    base.cssClass({
        position: 'relative',
        fontSize: '18px',
        padding: '20px',
        overflow: 'hidden',
        backgroundColor: '#dbf9f2',
        borderRadius: '20px',
        margin: '10px 0',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center'

    })
    const exit = Div('خروج').style({ cursor: 'pointer', backgroundColor: 'white', padding: '5px 10px', borderRadius: '10px' })
    base.append(exit)

    exit.el.onclick = async () => {
        ldb.remove('flep-user')
        await services.auth.logout()
        base.emit('exit')
    }
    return base
}