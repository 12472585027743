import { Div } from "./base/components/native/div"
import router from "./base/lib/router"
import { AddClientPage } from "./pages/add-client"
import { AddAppointmentPage } from "./pages/appointments/add"
import { ClientPage } from "./pages/client"
import { EditPage } from "./pages/edit"
import { AddAppointmentFromDayPage } from "./pages/home/calendar-tab/add-appointment"
import { HomePage } from "./pages/home"
import { AddIncomePage } from "./pages/incomes/add-income-page"
import { LoginPage } from "./pages/login"
import { NewNotificationPage } from "./pages/notifications/new-notification-page"
import { RepeatAppointmentPage } from "./pages/repeat/repeat-appointment-page"
import { BackIcon } from "./pages/shared/back-icon"
import { MenuIcon } from "./pages/shared/menu-icon"
import configs from "./configs"
import { AppointmentPage } from "./pages/appointments/view"
import { MenuPage } from "./pages/menu"
import { ReminderDetailsPage } from "./pages/reminders/details"
import { SetupReminderPage } from "./pages/reminders/setup"
import { NewEditPage } from "./pages/edit/new-edit-page"

const view = Div()
const app = Div()
const menuIcon = MenuIcon()
const backIcon = BackIcon()
app.append(menuIcon, backIcon, view)

app.cssClass({
    margin: '0',
    transition: 'all .16s',
    overflow: 'hidden',
    position: 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    paddingTop: 'env(safe-area-inset-top)',
    paddingBottom: 'env(safe-area-inset-bottom)',
    [`@media (max-width: ${configs.sizes.MOBILE}px)`]: {
        margin: '0',
    }
})

const routes = {
    '/': HomePage,
    '/:date/add-appointment-from-day': AddAppointmentFromDayPage,
    '/menu': MenuPage,
    // '/about': About,
    '/login': LoginPage,
    '/clients/add': AddClientPage,
    '/clients/:clientId': ClientPage,
    '/clients/:clientId/edit/:field': EditPage,
    '/clients/:clientId/appointments/add': AddAppointmentPage,
    '/clients/:clientId/appointments/:appointmentId': AppointmentPage,
    '/clients/:clientId/appointments/:appointmentId/edit/:field': EditPage,
    '/appointments/:appointmentId/notifications/add': NewNotificationPage,
    '/appointments/:appointmentId/repeat': RepeatAppointmentPage,
    '/appointments/:appointmentId/repeat/edit/:field': EditPage,
    // '/edit': EditPage,
    // '/clients/:clientId/incomes/add': AddIncomePage,
    // '/clients/:clientId/reminders/': SetupReminderPage, list of reminders
    '/clients/:clientId/reminders/setup': SetupReminderPage,
    '/clients/:clientId/reminders/:appointmentId/details': ReminderDetailsPage,
    '/clients/:clientId/reminders/:appointmentId/details/edit/:field': NewEditPage,
    // '/clients/:id/edit': AddClientPage,
    // '/clients/:id/transactions': TransactionsPage,
    // '/clients/:id/transactions/add': AddTransactionPage,
    // '/clients/:id/transactions/:transactionId': TransactionPage,
    // '/clients/:id/transactions/:transactionId/edit': AddTransactionPage,

}

router.init({ routes, view })



export default app