import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import locale from "../../../helpers/locale"
import { TAppointment } from "../../../interfaces/appointments"
import { TClient } from "../../../interfaces/client"
import services from "../../../services"
import flags, { FLAGS } from "../../../services/flags"
import { AppointmentReminder } from "../appointment-reminder"
import { Price } from "../price"

export const AppointmentItemDaily = (item: TAppointment) => {

    const base = Div()
    base.cssClass({
        margin: '10px 44px 50px 25px',
        borderRight: `1px solid #ea4c89`,
        padding: '0 15px',
        cursor: 'pointer',
        textAlign: 'right',
        position: 'relative',
    })


    const circleStart = Div()
    circleStart.cssClass({
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        border: '3px solid #ea4c89',
        position: 'absolute',
        top: '0px',
        right: '-6px',
    })
    // base.append(circleStart)


    const timeStart = Div(locale.replaceLatinDigits(item.time?.from))
    timeStart.cssClass({
        position: 'absolute',
        top: '-5px',
        right: '-48px',
        fontSize: '14px',
        color: '#666',
        opacity: '0.8'
    })
    base.append(timeStart)


    const circleEnd = Div()
    circleEnd.cssClass({
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        border: '3px solid #ea4c89',
        position: 'absolute',
        bottom: '0px',
        right: '-6px',
    })
    // base.append(circleEnd)


    const timeEnd = Div(locale.replaceLatinDigits(item.time?.to))
    timeEnd.cssClass({
        position: 'absolute',
        bottom: '-5px',
        right: '-48px',
        fontSize: '14px',
        color: '#666',
        opacity: '0.8'
    })
    base.append(timeEnd)


    const client = Div()
    services.clients.byId<TClient>(item.clientId).then((c) => {
        client.text(c?.name || 'نامشخص')
    })
    client.cssClass({
        fontSize: '18px',
        fontWeight: 'bold',
        position: 'relative',
        top: '-10px',
    })
    base.append(client)


    const description = Div(item.description)
    description.cssClass({
        fontSize: '16px',
        margin: '0px 0 14px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical'
    })
    base.append(client, description)


    const fees = Price(item.fees)
    fees.cssClass({
        fontSize: '14px',
        color: '#ea4c89',
        marginTop: '5px'
    })
    if (item.fees) base.append(fees)


    // const edit = Img(images.ICONS.EDIT, { width: 32 })
    // edit.cssClass({
    //     position: 'absolute',
    //     opacity: '0.3',
    //     top: 'calc(50% - 26px)',
    //     left: '-10px',
    // })
    // ghostify(edit, { bg: '#eee' })
    // base.append(edit)
    // edit.el.onclick = (e) => {
    //     e.stopPropagation()
    //     router.goto(`/clients/${item.clientId}/appointments/${item.id}`)
    // }


    if (FLAGS.REMINDER_IS_NOT_SET && new Date() < new Date(item.date)) {
        // todo: check when we set reminder it also needs to be applied to all prevoius appointments
        const reminder = AppointmentReminder(item)
        base.append(reminder)
    }
    const arrow = Img(images.ICONS.ARROW_LEFT2, { width: 32 })
    base.el.onclick = (e) => {
        router.goto(`/clients/${item.clientId}/appointments/${item.id}`)
    }
    ghostify(base, { bg: '#eee' })
    arrow.cssClass({
        position: 'absolute',
        opacity: '0.3',
        top: 'calc(50% - 26px)',
        left: '-20px',
        // transform: 'rotate(22.5deg)',
    })
    // base.append(arrow)

    return base
}