import { Div } from "../../../base/components/native/div"
import { Img } from "../../../base/components/native/img"
import { CENTER } from "../../../base/helpers/style"
import images from "../../../configs/images"

export const FirstClientAdded = () => {
    const base = Div()
    const icon = Img(images.BANNERS.HURRAY, { width: 180 })
    const title = Div('اولین مشتری اضافه شد! با کلیک روی نام مشتری به صفحه او بروید و اطلاعات بیشتری را ثبت یا مشاهده کنید.')
    base.append(icon, title)

    title.cssClass({
        textAlign: 'center',
        fontSize: '14px',
        margin: '20px 0',
        opacity: '.5'
    })

    base.cssClass({
        margin: '50px 60px 10px',
        ...CENTER,
        flexDirection: 'column',
    })

    return base
}