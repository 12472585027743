import { Div } from '../../../base/components/native/div'
import state from '../../../base/services/state'
import helpers from '../../../helpers'
import { ActionBack } from '../../shared/action-back'
import { ActionButton } from '../../shared/action-button'
import { DInput } from '../../shared/d-input'
import { WizardSlide } from '../../shared/slide/slide'

export const TelegramSlide = () => {

    const base = WizardSlide()
    const tele = DInput('شماره تلگرام', '09123467890', { size: 36, pattern: '[0-9]*', inputmode: 'numeric' })
    const next = ActionButton('ذخیره')
    const prev = ActionBack(helpers.strings.PREV_STEP)
    const keys = Div()
    keys.append(next, prev)
    base.append(tele, keys)

    next.disable()
    next.on('click', handleNext)
    prev.on('click', () => base.emit('prev'))
    tele.on('input', ({ value }: any) => value.length > 0 ? next.enable() : next.disable())
    base.on('enter', () => { tele.setValue(state.get('new-client')?.contact?.cell) })
    base.on('enter', () => setTimeout(() => tele.focus(), 450))
    base.on('reset', () => tele.clear())
    tele.on('key-enter', handleNext)

    function handleNext() {
        if (tele.getValue().length === 0) return
        let newClient = state.get('new-client')
        newClient.contact.telegram = helpers.locale.replacePersianDigits(tele.getValue());
        state.set('new-client', newClient)
        base.emit('done')
    }

    next.cssClass({
        backgroundColor: '#1cd4a2'
    })
    keys.cssClass({
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}