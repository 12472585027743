import router from "../../../../base/lib/router"
import images from "../../../../configs/images"
import helpers from "../../../../helpers"
import { TClient } from "../../../../interfaces/client"
import clients from "../../../../services/clients"
import { DangerButton } from "../../../shared/danger-button"
import { Prop } from "../../../shared/prop"
import { Tab } from "../../../shared/tabs/tab"
import { ProfileImage } from "./image"


export const ProfileTab = () => {

    const base = Tab()
    base.cssClass({
        width: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        paddingBottom: '20px'
    })


    const image = ProfileImage()
    const name = Prop('نـام و نام خانوادگی', 'text', 'name')
    const phone = Prop('تلفن', 'phone', 'cell')
    const telegram = Prop('تلگرام', 'telegram', 'telegram')

    const remove = DangerButton('حذف مشتری')
    remove.cssClass({
        margin: '30px 60px',
        width: 'fit-content',
        minWidth: 'unset',
        height: '44px',
    })
    remove.on('click', async () => {
        const id = location.pathname.split('/').pop()
        if (!id) return
        await clients.delete(id)
        router.back()
    })

    base.append(image, name, phone, telegram, remove)

    function refresh(client: TClient) {
        name.setData(client.name)
        phone.setData(helpers.locale.replaceLatinDigits(client.contact.cell))
        telegram.setData(helpers.locale.replaceLatinDigits(client.contact.telegram))
    }

    return Object.assign(base, {
        refresh,
        label: 'پروفایل',
        id: 'profile',
        icon: images.ICONS.CLIENTS,
        onFocus() {
            console.log('Client Profile Tab entered')
        },
        onBlur() {
            console.log('Client Profile Tab exited')
        },
    })
}