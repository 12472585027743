import { Div } from '../../../../base/components/native/div'
import { CENTER, WH } from '../../../../base/helpers/style';
import helpers from '../../../../helpers';

export const Time = (time: string = '00:00') => {
    
    const base = Div(helpers.locale.replaceLatinDigits(time))

    base.cssClass({
        ...CENTER,
        height: '60px',
        letterSpacing: '6px',
        fontSize: '22px',
        scrollSnapAlign: 'start',
        direction: 'ltr',
        padding: '0 0 0 9px',
        textAlign: 'center',
    })

    return base
}