import { jDateFormatter } from "../../../base/helpers/date"
import helpers from "../../../helpers"
import { PageHeader } from "../page-header"

export const DayTitle = (date: Date) => {
    const [wd, day, ...rest] = jDateFormatter.format(date).replace(',', '').split(' ').reverse()
    const title = `${wd} <b style="padding: 0 5px">${day}</b>`
    const base = PageHeader(helpers.locale.replaceLatinDigits(title))

    base.cssClass({
        fontWeight: 'normal',
        fontSize: '18px',
        borderBottom: '1px solid #eaeaea',
    })
    return base
}