import state from '../../../../base/services/state'
import { ActionButton } from '../../action-button'
import { DayPicker } from '../../calendar/day-picker/day-picker'
import { WizardSlide } from '../../slide/slide'
import { Div } from '../../../../base/components/native/div'
import helpers from '../../../../helpers'
import { Select } from '../../select/select'
import services from '../../../../services'
import { TClient } from '../../../../interfaces/client'

export const UserSlide = () => {





    const base = WizardSlide()
    const name = Div('انتخاب مشتری/مراجع')
    const user = Select()
    const next = ActionButton(helpers.strings.NEXT_STEP)
    base.append(name, user, next)

    services.clients.get(0).then((clients: TClient[]) => {
        user.setValue(clients, clients[0].id)
    })

    next.on('click', () => { state.merge('new-appointment', { clientId: user.getValue() }); base.emit('next') })
    // base.on('enter', () => setTimeout(name.focus, 450))
    // base.on('reset', () => name.clear())
    name.cssClass({
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '10px',
    })
    next.cssClass({
        marginTop: '30px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return base
}