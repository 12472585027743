import { ghostify } from "../../../base/components/advanced/ghost/ghost"
import { jDateFormatter } from "../../../base/helpers/date"
import helpers from "../../../helpers"
import { emitter } from "../../../services/emitter"
import { PageHeader } from "../page-header"

export const MonthTitle = ({ jm, jy }: { jm: number, jy: number }) => {
    const title = `${helpers.locale.jMonths[jm - 1]} ${jy}`
    const base = PageHeader(helpers.locale.replaceLatinDigits(title))


    base.cssClass({
        fontWeight: 'bold',
        fontSize: '22px',
    })

    base.el.onclick = () => {
        emitter.emit('month-clicked')
    }
    ghostify(base, { bg: '#fff' })
    return base
}