import { Div } from "../../../base/components/native/div"
import router from "../../../base/lib/router"
import images from "../../../configs/images"
import services from "../../../services"
import { ActionButton } from "../action-button"
import { AppointmentItemDaily } from "./appointment-item"
import { DayTitle } from "./day-title"

export const Day = (date: Date) => {

    const base = Div()
    base.cssClass({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        padding: '0px 0 120px 0',
        direction: 'rtl',
    })
    const title = DayTitle(date)
    base.append(title)

    const list = Div()
    base.append(list)

    const add = ActionButton('ثبت نوبت جدید ', { icon: images.ICONS.ADD })
    add.cssClass({
        width: 'fit-content',
        margin: '20px auto 40px',
    })
    add.on('click', () => {
        const year = date.getFullYear() // todo move to helpers
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        router.goto(`/${year}-${month}-${day}/add-appointment-from-day`)
    })
    list.cssClass({
        paddingTop: '60px',
        marginTop: '30px',
        marginRight: '60px',
        // borderRight: '3px dotted #ddd',
    })

    services.appointments.byDate(date).then(appointments => {
        appointments.sort((a: any, b: any) => a.time.from > b.time.from ? 1 : -1)
        appointments
            .filter((appointment: any) => !appointment.isDeleted)
            .forEach(appointment => {
                const item = AppointmentItemDaily(appointment)
                list.append(item)
            })
        base.append(add) // prevent flickering
    })





    // دشت امروز
    // const list = Div()
    // list.cssClass({
    //     display: 'flex',
    //     flexDirection: 'column',
    //     width: '100%',
    //     height: '100%',
    //     overflow: 'hidden',
    //     overflowY: 'auto',
    //     padding: '60px 0 120px 0'
    // })
    // base.append(list)

    // for (let i = 0; i < 24; i++) {
    //     const div = Div(i + '')
    //     div.cssClass({
    //         width: '100%',
    //         height: '40px',
    //         paddingTop: '10px',
    //         color: '#bbb',
    //         paddingRight: '22px',
    //         borderBottom: '1px solid #eee',
    //         flexShrink: '0',
    //     })
    //     list.append(div)
    // }
    // list.el.scrollTop = 480

    return base
}