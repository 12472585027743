import ldb from "../base/lib/ldb";
import { XHR } from "../base/lib/xhr";

async function getSubscriptionStatus(phone: string) {
    return XHR.get('/api/reminder/users/' + phone + '/subscription/status')
}

async function createSubscriptionToken(phone: string) {
    return XHR.post('/api/reminder/users/' + phone + '/subscription/token', {})
}

export default {
    getSubscriptionStatus,
    createSubscriptionToken
}