import { Base } from "../../../base/components/base"
import { Div } from "../../../base/components/native/div"
import locale from "../../../helpers/locale"
import { ActionBack } from "../../shared/action-back"
import { ActionButton } from "../../shared/action-button"
import { PriceInput } from "../../shared/price-input"


export const EditPrice = (label: string, value: string) => {

    const base = Base()
    const cost = PriceInput(label, value)
    const next = ActionButton('تایید')
    const prev = ActionBack('لغو')
    const keys = Div()
    keys.append(next, prev)
    base.append(cost, keys)

    cost.setValue(value)
    next.on('click', () => base.emit('update'))
    prev.on('click', () => base.emit('cancel'))
    cost.on('input', ({ value }: any) => value.length > 0 ? next.enable() : next.disable())

    next.cssClass({
        backgroundColor: '#4ceacd'
    })
    keys.cssClass({
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'space-between',
        marginTop: '40px',
        gap: '16px',
    })
    base.cssClass({
        padding: '15px 60px',
    })

    return Object.assign(base, {
        getValue() {
            const raw = cost.getValue()
            const latinDigits = locale.replacePersianDigits(raw)
            const fees = parseInt(latinDigits)
            return fees
        }
    })
}